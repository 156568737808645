import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout/Container"

import { useWellnessSurveyCompleteImages } from "./hooks/useWellnessSurveyCompleteImages"
// import { WellnessSurveyContext } from "../context/WellnessSurveyContext"
import styles from "./utils/wellnessSurveyComplete.module.scss"

const Completed = (props) => {
  const { pageContext } = props
  const { module, content, nextPath } = pageContext
  const data = useWellnessSurveyCompleteImages()
  // const { wellnessSurveyDispatch } = useContext(WellnessSurveyContext)

  // useEffect(() => {
  //   const restartDetails = async () => {
  //     await wellnessSurveyDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
  //   }
  //   restartDetails()
  // }, [wellnessSurveyDispatch])

  return (
    <Layout
      seoTitle={module.seoTitle}
      isPrivate
      pageContext={pageContext}
      polygonStyle="completePages"
    >
      <Container isCentered>
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={8}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="title has-text-primary">{module.title}</h1>

          <div className="column">
            <Fragment>
              <h4 className="mb-2">{content.text}</h4>
            </Fragment>
          </div>
          <center>
            <Link to={nextPath} className="mt-1 button is-primary is-medium">
              {pageContext?.cta ? pageContext.cta : "Finish"}
            </Link>
          </center>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
